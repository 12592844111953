import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/Menus/Content'

interface Datas {
  slug: string
  title: string
}

const s = {
  container: `w-full h-fit py-24 section`,
  wrapper: `wrapper`,
  title: `-mt-4 mb-12`,
}

const Menus = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site.md/" }) {
        frontmatter {
          menu {
            slug
            title
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/menus/" } }
        sort: { fields: frontmatter___menus___id, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              menus {
                id
                title
                description
                price
                tag
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.menu as Datas

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
        <Content datas={datas} />
      </div>
    </section>
  )
}

export default Menus
