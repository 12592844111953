import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Image from 'main/Hero/Image'
import Content from 'main/Hero/Content'

interface Datas {
  slug: string
}

const s = {
  container: `relative w-full h-screen`,
  wrapper: `wrapper relative h-full flex items-center`,
}

const Hero = () => {
  const datas = useStaticQuery(
    graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/site.md/" }) {
          frontmatter {
            hero {
              slug
              title
              subtitle
              link
              text
              image {
                alt
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: DOMINANT_COLOR
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { slug } = datas.markdownRemark.frontmatter.hero as Datas

  return (
    <section className={s.container} id={slug}>
      <Image datas={datas} />
      <div className={s.wrapper}>
        <Content datas={datas} />
      </div>
    </section>
  )
}

export default Hero
