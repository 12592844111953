import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useModalContext } from 'hooks/modal-context'

import Modal from 'shared/Modal/Modal'
import Slider from 'shared/Slider/Slider'

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            galleries: {
              id: number
              tag: string
              image: {
                alt: string
                src: any
              }
            }[]
          }
        }
      }[]
    }
  }
}

const s = {
  group: `w-full h-full`,
  title: `absolute top-4 right-4 z-2 mb-0 text-2xl text-white uppercase`,
  count: `absolute top-12 right-4 z-2 mb-0 text-base text-white`,
  image: `w-full h-full`,
}

const Image = ({ datas }: Datas) => {
  const { openModal } = useModalContext()

  const images = datas.allMarkdownRemark.edges[0].node.frontmatter.galleries

  return (
    <Modal openModal={openModal}>
      <Slider autoPlay={true}>
        {images.map(({ image }, id) => (
          <div key={id} className={s.group}>
            <GatsbyImage
              className={s.image}
              image={image.src.childImageSharp.gatsbyImageData}
              alt={image.alt}
              loading="eager"
            />

            <p className={s.title}>{image.alt.replace('pizza', '')}</p>
            <p className={s.count}>{`${id + 1} of ${images.length}`}</p>
          </div>
        ))}
      </Slider>
    </Modal>
  )
}

export default Image
