import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Form from 'main/Contact/Form'
import Info from 'shared/Info/Info'
import Map from 'shared/Map/Map'

interface Datas {
  slug: string
  title: string
}

const s = {
  container: `w-full h-fit pt-24 section`,
  wrapper: `wrapper`,
  title: `-mt-4 mb-12`,
  grid: `grid-2 gap-20 mdx:grid-cols-1`,
  direction: `grid-1 -mt-6`,
}

const Contact = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          contact {
            slug
            title
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.contact as Datas

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
        <div className={s.grid}>
          <Info direction={s.direction} />
          <Form />
        </div>
      </div>
      <Map />
    </section>
  )
}

export default Contact
