import React from 'react'

const Map = () => {
  return (
    <section className="w-full h-h50 smx:h-h32">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12097.226251888407!2d-74.0122359093275!3d40.71126737866784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1612866587779!5m2!1sen!2sus"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen={false}
        aria-hidden="false"
        title="location new york map"
      ></iframe>
    </section>
  )
}

export default Map
