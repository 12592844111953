import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/Post/Content'

import LinkButton from 'shared/Button/LinkButton'

interface Datas {
  slug: string
  title: string
}

const s = {
  container: `w-full h-fit py-24 section`,
  wrapper: `wrapper`,

  flex: `flexbetween mb-12`,
  title: `mb-0`,
  button: `block `,
}

const Post = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          post {
            slug
            title
          }
        }
      }
      allMarkdownRemark(
        limit: 3
        filter: { fileAbsolutePath: { regex: "/posts/" } }
        sort: { fields: frontmatter___posts___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              posts {
                id
                slug
                title
                date
                snippet
                image {
                  alt
                  src {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: DOMINANT_COLOR
                        quality: 80
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.post as Datas

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <div className={s.flex}>
          <h2 className={s.title}>{title}</h2>

          <LinkButton to={`/posts/`} classname={s.button}>
            All News
          </LinkButton>
        </div>
        <Content datas={datas} />
      </div>
    </section>
  )
}

export default Post
