import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { AnimationFadeIn } from 'shared/Animation/Animation'

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          image: {
            alt: string
            src: any
          }
        }
      }
    }
  }
}

const s = {
  container: `absolute top-0 left-0 z-1 w-full h-screen text-white`,
  overlay: `absolute top-0 left-0 z-2 w-full h-full bg-opblack`,
  group: `w-full h-screen flexcenter`,
  image: `w-full h-full text-transparent transition delay-300 ease-in`,
}

const Image = ({ datas }: Datas) => {
  const { image } = datas.markdownRemark.frontmatter.hero

  return (
    <section className={s.container}>
      <AnimationFadeIn delay={50} x={0} y={0}>
        <div className={s.overlay} />
        <div className={s.group}>
          <GatsbyImage
            className={s.image}
            image={image.src.childImageSharp.gatsbyImageData}
            alt={image.alt}
            loading="eager"
          />
        </div>
      </AnimationFadeIn>
    </section>
  )
}

export default Image
