import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import LinkButton from 'shared/Button/LinkButton'

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            posts: {
              id: number
              slug: string
              title: string
              date: string
              snippet: string
              description: string
              image: {
                src: any
                alt: string
              }
            }
          }
        }
      }[]
    }
  }
}

const s = {
  container: `w-full h-fit`,
  grid: `grid-3 gap-12 mdx:grid-cols-1 mdx:gap-0`,
  card: `relative w-full h-fit mdx:mb-20 mdx:last:mb-0`,

  link: `w-full h-full`,
  image: `w-full h-h55 2xlm:h-h50 xlm:h-h45 lgm:h-h40 mdm:h-h50 smm:h-h55 xsx:h-h35`,

  body: `w-full h-fit flex flex-col pt-12 px-4`,
  title: `-mb-2 text-h4`,
  date: `mb-6 text-sm`,
  snippet: `mb-16 flex-grow text-base leading-loose text-darkgray`,
}

const Content = ({ datas }: Datas) => {
  const posts = datas.allMarkdownRemark.edges

  return (
    <div className={s.container}>
      <div className={s.grid}>
        {posts.map(({ node }) => {
          const { id, slug, title, date, snippet, image } =
            node.frontmatter.posts

          return (
            <div key={id} className={s.card}>
              <Link className={s.link} to={`/post/${slug}/`} aria-label={title}>
                <GatsbyImage
                  className={s.image}
                  image={image.src.childImageSharp.gatsbyImageData}
                  alt={image.alt}
                  loading="eager"
                />
              </Link>
              <div className={s.body}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.date}>{date}</p>
                <p className={s.snippet}>{snippet}...</p>

                <LinkButton classname="" to={`/post/${slug}/`}>
                  Read News
                </LinkButton>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Content
