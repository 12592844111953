import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useModalContext } from 'hooks/modal-context'

import { AnimationFadeIn } from 'shared/Animation/Animation'

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            galleries: {
              id: number
              tag: string
              image: {
                alt: string
                src: any
              }
            }[]
          }
        }
      }[]
    }
  }
}

const s = {
  grid: `grid-4 gap-0 mdm:grid-2 smm:grid-2 xsx:grid-cols-1`,
  link: `w-full h-h50 text-center cursor-pointer 2xlm:h-h32 lgm:h-h25 mdx:h-h35`,
  image: `w-full h-full transition-all delay-500 ease-out`,
  text: `w-full h-fit flex justify-center items-center`,
}

const Content = ({ datas }: Datas) => {
  const { setOpenModal, setIndex } = useModalContext()

  const openClick = (id: number) => {
    setIndex(id)
    setOpenModal(true)
  }

  const images = datas.allMarkdownRemark.edges[0].node.frontmatter.galleries

  return (
    <div className={s.grid}>
      <div className={s.grid}>
        {images.map(({ id, image }) => (
          <AnimationFadeIn key={id} x={0} y={0} delay={300}>
            <div className={s.link} onClick={() => openClick(id)}>
              <GatsbyImage
                className={s.image}
                image={image.src.childImageSharp.gatsbyImageData}
                alt={image.alt}
                loading="eager"
              />
            </div>
          </AnimationFadeIn>
        ))}
      </div>
    </div>
  )
}

export default Content
