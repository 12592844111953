import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/Special/Content'

interface Datas {
  slug: string
  title: string
}

const s = {
  container: `w-full h-fit py-24 section`,
  wrapper: `wrapper`,
  title: `-mt-4 mb-12`,
}

const Special = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site.md/" }) {
        frontmatter {
          special {
            slug
            title
          }
        }
      }
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/specials/" } }) {
        nodes {
          frontmatter {
            specials {
              contents {
                id
                slug
                title
                description
                price
              }
              images {
                id
                alt
                src {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      placeholder: DOMINANT_COLOR
                      quality: 100
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.special as Datas

  return (
    <div className={s.container} id={slug}>
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>

        <Content datas={datas} />
      </div>
    </div>
  )
}

export default Special
