import React from 'react'

import { useTabsContext } from 'hooks/tabs-context'

const s = {
  content: (shows: any) => `
  ${shows ? `visible opacity-100` : `hidden opacity-0`}
  transition-all delay-500 ease-out duration-500`,
}

const TabsContent = ({ tag, children }: any) => {
  const { shows } = useTabsContext()

  return <div className={s.content(shows === tag)}>{children}</div>
}

export default TabsContent
