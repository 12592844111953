import React from 'react'
import Markdown from 'markdown-to-jsx'
import { useStaticQuery, graphql } from 'gatsby'

interface Info {
  id: number
  title: string
  text: string
  image: {
    alt: string
    width: number
    height: number
    src: {
      publicURL: string
    }
  }
}

interface Datas {
  direction: string
}

const s = {
  container: `relative w-full h-fit`,
  flex: `flex flex-row items-start my-6`,
  image: `w-9 h-9 mr-3 text-filters`,
  content: `flex flex-col -mt-3`,
  title: `-mb-2 text-3xl font-main font-bold`,
  text: `mb-0 info`,
}

const Info = ({ direction }: Datas) => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/site/" } }) {
        edges {
          node {
            frontmatter {
              info {
                id
                title
                text
                image {
                  alt
                  width
                  height
                  src {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const infos = datas.allMarkdownRemark.edges[0].node.frontmatter.info

  return (
    <section className={`${s.container}  ${direction}`}>
      {infos.map(({ id, title, text, image }: Info) => (
        <div className={s.flex} key={id}>
          <img
            className={s.image}
            src={image.src.publicURL}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
          <div className={s.content}>
            <h3 className={s.title}>{title}</h3>
            <Markdown className={s.text}>{text}</Markdown>
          </div>
        </div>
      ))}
    </section>
  )
}

export default Info
