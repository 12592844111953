export const s = {
  // FORM
  form: `w-full pb-48`,
  flex: `w-full flex justify-between xlx:flex-col`,

  group: `flex flex-col mb-16`,
  label: `mb-4 text-2xl font-bold text-black first:-mt-4`,
  input: `p-4 text-lg font-inherit rounded border-1 border-solid border-gray bg-gray text-darkgray focus:outline-none`,
  select: `p-4 text-lg font-inherit border-1 border-solid border-gray bg-gray text-darkgray focus:outline-none`,
  option: `text-lg font-inherit bg-gray text-darkgray border-1 border-solid border-gray hover:text-red`,
  message: `h-80 resize-y p-4 text-lg font-inherit rounded border border-solid border-gray bg-gray text-darkgray focus:outline-none`,
  button: ``,

  // CHECK BOX
  checkbox: `relative w-fit-content mb-20`,
  checklabel: `flex align-center mb-0 text-lg cursor-pointer checklabel`,
  checkspan: `block checkspan`,
  checkinput: `w-9 h-9 mr-4 appearance-none bg-gray border-1 border-solid border-gray rounded outline-none transition delay-300 ease-in curser-pointer checkinput checked:bg-gray checked:border checked:border-solid checked:border-gray active:border active:border-solid active:border-darkgray`,

  // MESSAGE WARNING
  error: `mt-3 text-red`,
}
