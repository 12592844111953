import React from 'react'

import { useTabsContext } from 'hooks/tabs-context'

const s = {
  tabsbody: (clicked: any) => ` ${clicked ? `bg-lightgray` : `bg-transparent`}
  grid-2 gap-20 mt-16 transition-all delay-200 ease-out mdx:grid-cols-1`,
}

const TabsBody = ({ children }: any) => {
  const { clicked } = useTabsContext()

  return <div className={s.tabsbody(clicked)}>{children}</div>
}

export default TabsBody
