import React from 'react'

import { AnimationTrail } from 'shared/Animation/Animation'

import AnchorButton from 'shared/Button/AnchorButton'
import Down from 'shared/Down/Down'

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          title: string
          subtitle: string
          link: string
          text: string
        }
      }
    }
  }
}

const s = {
  content: `relative z-2 w-fit h-fit flex flex-col text-white 
  smm:mt-16 xsx:w-full`,
  title: `-mt-4 -mb-12 screenxs:-mb-9 xsx:-mb-4 xsx:leading-none`,
  subtitle: `mb-8 text-clamp-3xl`,
}

const Content = ({ datas }: Datas) => {
  const { title, subtitle, link, text } = datas.markdownRemark.frontmatter.hero

  return (
    <div className={s.content}>
      <AnimationTrail delay={1100} x={0} y={0}>
        <h1 className={s.title}>{title}</h1>
        <p className={s.subtitle}>{subtitle}</p>
        {/* <AnchorButton classname="" to={`/${link}`}>
          {text}
        </AnchorButton> */}

        <Down />
      </AnimationTrail>
    </div>
  )
}

export default Content
