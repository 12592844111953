import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/Gallery/Content'
import Image from 'main/Gallery/Image'

interface Datas {
  slug: string
  title: string
}

const s = {
  container: `w-full h-fit py-24 section`,
  wrapper: `wrapper`,
  title: `-mt-4 mb-12`,
}

const Gallery = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          gallery {
            slug
            title
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/galleries/" } }
      ) {
        edges {
          node {
            frontmatter {
              galleries {
                id
                title
                tag
                image {
                  alt
                  src {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        quality: 80
                        placeholder: DOMINANT_COLOR
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.gallery as Datas

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
        <Image datas={datas} />
      </div>
      <Content datas={datas} />
    </section>
  )
}

export default Gallery
