import React from 'react'

const s = {
  header: `w-full h-fit flex flex-row flex-wrap justify-center items-center overflow-hidden`,
}

const TabsHeader = ({ children }: any) => {
  return <div className={s.header}>{children}</div>
}

export default TabsHeader
