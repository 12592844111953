import React from 'react'

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        about: {
          title: string
          description: string
        }
      }
    }
  }
}

const s = {
  content: `w-full h-fit text-black`,
  title: `-mt-4 mb-12`,
  description: `-mt-4 text-base leading-loose`,
}

const Content = ({ datas }: Datas) => {
  const { title, description } = datas.markdownRemark.frontmatter.about

  return (
    <div className={s.content}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.description}>{description}</p>
    </div>
  )
}

export default Content
