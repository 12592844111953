import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'

import { s } from 'main/Contact/_contactform'

import Checkbox from 'main/Contact/Checkbox'
import FormButton from 'shared/Button/FormButton'

interface Contact {
  name: string
  email: string
  phone: number
  time: string
  date: string
  guest: number
  message: string
  checkbox: boolean
  errors: any
}

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<Contact>({
    mode: 'onChange',
  })

  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    time: '',
    date: '',
    guest: '',
    message: '',
    checkbox: false,
  })

  const { name, email, phone, time, date, guest, message, checkbox } = values

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setValues({ ...values, [e.target.name]: e.target.value, checkbox: true })
  }

  const onSubmit = handleSubmit((data, e: any) => {
    e.preventDefault()

    const form = e.target

    axios({
      method: 'post',
      url: 'https://formspree.io/f/mwkwvdvr',
      data: new FormData(form),
    })
      .then((response) => {
        if (checkbox) e.target.reset()

        toast.success(`Thanks for reaching out. I'll get back to you soon.`)

        setValues({
          ...values,
          name: '',
          email: '',
          phone: '',
          time: '',
          date: '',
          guest: '',
          message: '',
          checkbox: false,
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(
          'Oops, something went wrong. The form could not be submitted.'
        )
        setValues({ ...values, checkbox: false })
      })
  })

  return (
    <div className="w-full h-fit-content">
      <Toaster position="top-center" reverseOrder={false} />

      <form name="contact" onSubmit={onSubmit} className={s.form}>
        <div className={s.group}>
          <label htmlFor="name" className={`${s.label} form-label`}>
            Name
          </label>
          <input
            {...register('name', {
              required: true,
              minLength: 3,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i,
            })}
            className={s.input}
            id="name"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={handleChange}
          />
          {errors.name && <p className={s.error}>Please enter a valid name.</p>}
        </div>

        <div className={s.group}>
          <label htmlFor="email" className={`${s.label} form-label`}>
            Email
          </label>
          <input
            {...register('email', {
              required: true,
              validate: (input: any) => isEmail(input),
              minLength: 6,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
            })}
            className={s.input}
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className={s.error}>Please enter a valid email.</p>
          )}
        </div>

        <div className={s.group}>
          <label htmlFor="phone" className={`${s.label} form-label`}>
            Phone (optional)
          </label>
          <input
            {...register('phone', {
              required: false,
              minLength: 10,
            })}
            className={s.input}
            id="phone"
            type="tel"
            name="phone"
            placeholder="Phone(10)"
            value={phone}
            onChange={handleChange}
          />
          {errors.phone && (
            <p className={s.error}>Please enter a valid phone number.</p>
          )}
        </div>

        <div className={s.flex}>
          <div className={s.group}>
            <label htmlFor="date" className={`${s.label} form-label`}>
              Date
            </label>
            <input
              {...register('date', {
                required: true,
              })}
              className={s.input}
              id="date"
              type="date"
              name="date"
              value={date}
              onChange={handleChange}
            />
            {errors.date && <p className={s.error}>Please select date.</p>}
          </div>

          <div className={s.group}>
            <label htmlFor="guest" className={`${s.label} form-label`}>
              Guest
            </label>
            <select
              {...register('guest', {
                required: true,
              })}
              className={s.select}
              id="guest"
              name="guest"
              value={guest}
              onChange={handleChange}
            >
              <option className={s.option} disabled value="">
                -- select a guest --
              </option>
              <option className={s.option}>2</option>
              <option className={s.option}>3</option>
              <option className={s.option}>4</option>
              <option className={s.option}>5</option>
              <option className={s.option}>6</option>
              <option className={s.option}>7</option>
            </select>
            {errors.guest && <p className={s.error}>Please select guest.</p>}
          </div>

          <div className={s.group}>
            <label htmlFor="time" className={`${s.label} form-label`}>
              Time
            </label>
            <select
              {...register('time', {
                required: true,
              })}
              className={s.select}
              id="time"
              name="time"
              value={time}
              onChange={handleChange}
            >
              <option className={s.option} disabled value="">
                -- select a time --
              </option>
              <option className={s.option}>09:00AM - 10:00AM</option>
              <option className={s.option}>10:00AM - 11:00AM</option>
              <option className={s.option}>11:00AM - 12:00PM</option>
              <option className={s.option}>12:00PM - 13:00PM</option>
              <option className={s.option}>13:00PM - 14:00PM</option>
              <option className={s.option}>14:00PM - 15:00PM</option>
              <option className={s.option}>15:00PM - 16:00PM</option>
              <option className={s.option}>16:00PM - 17:00PM</option>
            </select>

            {errors.time && <p className={s.error}>Please select time.</p>}
          </div>
        </div>

        <div className={s.group}>
          <label htmlFor="message" className={`${s.label} form-label`}>
            Message
          </label>
          <textarea
            {...register('message', {
              required: true,
              maxLength: 300,
            })}
            className={s.message}
            id="message"
            name="message"
            placeholder="Message"
            value={message}
            onChange={handleChange}
          />
          {errors.message && (
            <p className={s.error}>Please enter a valid message.</p>
          )}
        </div>

        <Checkbox
          register={register}
          handleChange={handleChange}
          errors={errors}
        />

        <FormButton classname={s.button} disabled={formState.isSubmitting}>
          Send
        </FormButton>
      </form>
    </div>
  )
}

export default Form
