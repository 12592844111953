import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Content from 'main/About/Content'
import Image from 'main/About/Image'
import Infos from 'main/About/Info'

interface Datas {
  slug: string
}

const s = {
  container: `w-full h-fit mt-24 py-24 section`,
  wrapper: `wrapper`,
  grid: `grid-2 items-center gap-28 mdx:grid-cols-1`,
}

const About = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site.md/" }) {
        frontmatter {
          about {
            slug
            title
            description
            image {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 70
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { slug } = datas.markdownRemark.frontmatter.about as Datas

  return (
    <section className={s.container} id={slug}>
      <div className={s.wrapper}>
        <div className={s.grid}>
          <Content datas={datas} />
          <Image datas={datas} />
        </div>
        <Infos />
      </div>
    </section>
  )
}

export default About
