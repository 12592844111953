import React from 'react'

import { useModalContext } from 'hooks/modal-context'

interface Modals {
  openModal: boolean
  children: any
}

const s = {
  container: (open: any) => `
  fixed top-0 left-0 z-99 w-full h-full flexcenter bg-opblack  
  ${open ? `opacity-100 visible` : `opacity-0 invisible`}
  transition-all delay-500 ease-out duration-500`,

  group: `w-7/12 h-h75 px-4 pb-12 rounded bg-white 
  2xlx:w-11/12 2xlm:h-h65 lgm:h-h60 mdm:h-h55 smm:h-h32 xsx:h-h50`,
  header: `w-full h-fit flexbetween py-4 `,
  button: `my-0 mr-2 ml-auto text-lg border-0 bg-transparent curser-pointer`,

  body: `w-full h-h70 2xlm:h-h60 lgm:h-h55 mdm:h-h50 smm:h-h28 xsx:h-h45`,
}

const Modal = ({ openModal, children }: Modals) => {
  const { setOpenModal } = useModalContext()

  const closeClick = () => setOpenModal(false)

  return (
    <section className={s.container(openModal)}>
      <div className={s.group}>
        <div className={s.header}>
          <button className={s.button} onClick={() => closeClick()}>
            Close
          </button>
        </div>
        <div className={`${s.body}`}>{children}</div>
      </div>
    </section>
  )
}

export default Modal
