import React from 'react'

import Tabs from 'shared/Tabs/Tabs'
import TabsHeader from 'shared/Tabs/TabsHeader'
import TabsTitle from 'shared/Tabs/TabsTitle'
import TabsBody from 'shared/Tabs/TabsBody'
import TabsContent from 'shared/Tabs/TabsContent'

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            menus: {
              id: number
              title: string
              description: string
              price: number
              tag: string
            }[]
          }
        }
      }[]
    }
  }
}

const s = {
  flex: `flex justify-between`,
  group: `flex flex-col items-start`,
  title: `-mt-4 -mb-4 text-3xl`,
  description: `w-w32 mb-0`,
  price: `-mt-2 mb-0 text-xl font-main font-bold`,
}

const Content = ({ datas }: Datas) => {
  const content = datas.allMarkdownRemark.edges.flatMap(
    ({ node }) => node.frontmatter.menus
  )

  const titles = Array.from(new Set(content.map(({ tag }) => tag)))

  return (
    <Tabs>
      <TabsHeader>
        {titles.map((tag, id) => (
          <TabsTitle key={id} tags={tag} id={`${tag}`}>
            {tag}
          </TabsTitle>
        ))}
      </TabsHeader>

      <TabsBody>
        {content.map(({ id, title, description, price, tag }) => (
          <TabsContent key={id} tag={tag} id={id}>
            <div className={s.flex}>
              <div className={s.group}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.description}>{description}</p>
              </div>

              <p className={s.price}>{`$${price}`}</p>
            </div>
          </TabsContent>
        ))}
      </TabsBody>
    </Tabs>
  )
}

export default Content
