import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        about: {
          image: {
            src: any
            alt: string
          }
        }
      }
    }
  }
}

const s = {
  image: `w-full h-h55 lgm:h-h40 mdm:h-h50 smx:h-h35`,
}

const Image = ({ datas }: Datas) => {
  const { image } = datas.markdownRemark.frontmatter.about

  return (
    <GatsbyImage
      className={s.image}
      image={image.src.childImageSharp.gatsbyImageData}
      alt={image.alt}
      loading="eager"
    />
  )
}

export default Image
