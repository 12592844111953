import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Seo from 'shared/Seo/Seo'
import Layout from 'shared/Layout/Layout'
import Hero from 'main/Hero/Hero'
import About from 'main/About/About'
import Special from 'main/Special/Special'
import Menus from 'main/Menus/Menus'
import Gallery from 'main/Gallery/Gallery'
import Post from 'main/Post/Post'
import Contact from 'main/Contact/Contact'

const IndexPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site.md/" }) {
        frontmatter {
          sites {
            title
            description
          }
        }
      }
    }
  `)

  const { title, description } = datas.markdownRemark.frontmatter.sites

  return (
    <Layout>
      <Seo title={title} description={description} />
      <Hero />
      <About />
      <Special />
      <Menus />
      <Gallery />
      <Post />
      <Contact />
    </Layout>
  )
}

export default IndexPage
