import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import AnchorButton from 'shared/Button/AnchorButton'

interface Datas {
  markdownRemark: {
    frontmatter: {
      down: {
        link: string
        text: string
        image: {
          alt: string
          src: any
          width: number
          height: number
        }
      }
    }
  }
}

const s = {
  container: `w-fit-content h-fit`,
  image: `w-8 h-8 filtercolor animate-bounce`,
  button: `bg-transparent`,
}

const Down = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site.md/" }) {
        frontmatter {
          down {
            id     
            link
            text
            image {
              alt
              width
              height
              src {
                publicURL
              }
            }
          }
        }
      }
    }
  `) as Datas

  const { link, text, image } = datas.markdownRemark.frontmatter.down

  return (
    <div className={s.container}>
      <AnchorButton classname={s.button} to={`/${link}`} aria-label={text}>
        <img
          className={s.image}
          src={image.src.publicURL}
          alt={image.alt}
          title={text}
          width={image.width}
          height={image.height}
        />
      </AnchorButton>
    </div>
  )
}

export default Down
