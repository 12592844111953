import React from 'react'

import { useTabsContext } from 'hooks/tabs-context'

const s = {
  title: (shows: any) => `
  ${shows ? `bg-yellow text-darkgray` : `bg-transparent text-black`}
  relative top-1 mr-6 px-8 py-4 w-fit h-fit capitalize text-clamp-xl font-main font-bold rounded-t-xl outline-none cursor-pointer transition-all delay-300 ease-out hover:bg-yellow hover:text-darkgray
  `,
}

const TabsTitle = ({ tags, id, children }: any) => {
  const { shows, setShows, setClicked } = useTabsContext()

  const handleClick = (e: any) => {
    const idx = e.target.id

    if (idx !== shows) {
      setShows(idx)
      setClicked(shows === tags)
    }
  }

  return (
    <button
      className={s.title(shows === tags)}
      id={`${id}`}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export default TabsTitle
