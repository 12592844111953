import React from 'react'

import Infos from 'shared/Info/Info'

const s = {
  content: `w-full h-fit mt-28 py-24 bg-yellow rounded-lg`,
  direction: `grid-3 items-center infos smx:grid-1`,
}

const Info = () => {
  return (
    <div className={s.content}>
      <Infos direction={s.direction} />
    </div>
  )
}

export default Info
