import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Datas {
  datas: {
    allMarkdownRemark: {
      nodes: {
        frontmatter: {
          specials: {
            contents: {
              id: number
              slug: string
              title: string
              description: string
              price: number
            }[]
            images: {
              id: number
              alt: string
              src: any
            }[]
          }
        }
      }[]
    }
  }
}

const s = {
  grid: `grid-2 gap-28 mdx:grid-1 mdx:gap-20`,

  flex: `w-full h-full flex flex-col`,
  image: `w-full h-h32 -mt-1 lgm:h-80 mdm:h-h35`,

  group: `flex flex-col`,

  content: `grid-1 items-center mdx:gap-20`,
  title: `-mt-4 -mb-4 text-3xl`,
  description: `w-w45 mb-16 lgm:w-w32 lgm:mb-10`,
  price: `w-fit h-fit pt-2 pb-0 px-6 text-3xl font-main font-bold bg-yellow`,
}

const Content = ({ datas }: Datas) => {
  const { contents, images } =
    datas.allMarkdownRemark.nodes[0].frontmatter.specials

  const content = contents.map(({ id, title, description, price }) => (
    <div className={s.group} key={id}>
      <h3 className={s.title}>{title}</h3>
      <p className={s.description}>{description}</p>
      <p className={s.price}>{`$${price}`}</p>
    </div>
  ))

  const image = images.map(({ id, alt, src }) => (
    <GatsbyImage
      key={id}
      className={s.image}
      image={src.childImageSharp.gatsbyImageData}
      alt={alt}
      loading="eager"
    />
  ))

  return (
    <div className={s.grid}>
      <div className={s.flex}>{image.slice(0, 2)}</div>
      <div className={s.content}>{content.slice(0, 2)}</div>
      <div className={`${s.content} mdx:order-4`}>{content.slice(2, 4)}</div>
      <div className={s.flex}>{image.slice(2, 4)}</div>
    </div>
  )
}

export default Content
